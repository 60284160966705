const LocationReviewsData = [
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Eric Conrad",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Amanda N.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-10",
        "review_rate": 5.0,
        "customer_name": "Richard Murphy",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jared D.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Clayton Henderson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Carrie M.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Tiffany Myers",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Carrie M.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.8,
        "customer_name": "Michelle Collins",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kimberly S.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Dana Nelson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Andrew J.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Nichole Lee",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Luke W.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.8,
        "customer_name": "Laura Ramirez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kayla E.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-23",
        "review_rate": 5.0,
        "customer_name": "Patrick Gonzales",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Fernando H.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Jessica Daniel",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jonathan G.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Mary Miller",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Sean S.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Rebecca Goodman",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Ethan W.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-25",
        "review_rate": 5.0,
        "customer_name": "Randy Wade",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Brittany M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Aaron Marquez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michael D.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Anna Young",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Michael J.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-02",
        "review_rate": 5.0,
        "customer_name": "Steven May",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Amy O.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Nicholas Perez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Martha D.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.8,
        "customer_name": "Amber Lambert",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Carol A.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Mr. John Phelps",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Whitney G.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Denise Davis",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Stacey R.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Jason Hansen",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Denise I.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Courtney Romero",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kelly R.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Steven Herman",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Rebecca F.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.8,
        "customer_name": "Russell Jacobs MD",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kelly T.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Shelby Barber",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Robert K.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Judy Phillips",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Tracy M.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-24",
        "review_rate": 5.0,
        "customer_name": "Andrew Perry",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Russell C.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Katie Kirby",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Mary S.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.8,
        "customer_name": "Ryan Morgan",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Gary F.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Stacy Johnston",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Mark L.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-21",
        "review_rate": 5.0,
        "customer_name": "Katrina Dunn",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Zachary O.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-23",
        "review_rate": 5.0,
        "customer_name": "Kelli Miller",
        "service_type": "Garage Door Off Track",
        "tech_name": "Charles P.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-28",
        "review_rate": 5.0,
        "customer_name": "Allison Tyler",
        "service_type": "Garage Door Services",
        "tech_name": "Johnny G.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.8,
        "customer_name": "Laura Hernandez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Ryan S.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.8,
        "customer_name": "Austin Baldwin",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Amanda R.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-23",
        "review_rate": 5.0,
        "customer_name": "Jonathan Morrison",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Derek B.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Joshua Mclaughlin",
        "service_type": "Garage Door Opener",
        "tech_name": "Ann Y.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.8,
        "customer_name": "Pamela Stewart",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jason D.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.8,
        "customer_name": "Kayla Doyle",
        "service_type": "Garage Door Off Track",
        "tech_name": "Angela M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-03",
        "review_rate": 5.0,
        "customer_name": "Michele Johnson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Luke D.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Lori Wilson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Matthew C.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Jimmy Pittman",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Anna S.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "Hailey Henderson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Amanda C.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.8,
        "customer_name": "Anthony Good",
        "service_type": "Garage Door Services",
        "tech_name": "David C.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.8,
        "customer_name": "Brian Lopez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Justin M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-04",
        "review_rate": 5.0,
        "customer_name": "Jessica Rivera",
        "service_type": "Garage Door Insulation",
        "tech_name": "Steven L.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.8,
        "customer_name": "Veronica Key",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jennifer B.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Dwayne Wagner",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jorge C.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Amber Holt",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Albert P.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Wesley Bauer",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Susan W.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Frances Gutierrez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Nancy C.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-09-28",
        "review_rate": 5.0,
        "customer_name": "Andrew Johnson",
        "service_type": "Garage Door Services",
        "tech_name": "Andrea E.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Nicholas Perez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Matthew S.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.8,
        "customer_name": "John Lam",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Brooke R.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "Richard Carlson",
        "service_type": "Garage Door Repair",
        "tech_name": "Kelly R.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.8,
        "customer_name": "William Huffman",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Eric G.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Kayla Cunningham",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Patricia R.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-18",
        "review_rate": 5.0,
        "customer_name": "Todd Bailey",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "John S.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Renee Garcia",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Tiffany R.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.8,
        "customer_name": "Amber Martinez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jeffrey M.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Kurt Harris",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Angela S.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.8,
        "customer_name": "Corey Leon",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Nathan Y.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-05",
        "review_rate": 5.0,
        "customer_name": "John Johnson MD",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Karen T.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Kenneth Johnson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "James C.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.8,
        "customer_name": "Kathy Collins",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Mary B.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Rachel Moyer",
        "service_type": "Garage Door Installation",
        "tech_name": "Kevin B.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-08",
        "review_rate": 5.0,
        "customer_name": "Timothy Sutton",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kimberly R.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Lynn Green",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Alicia W.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Manuel Weaver",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kenneth B.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Susan Marshall",
        "service_type": "Garage Door Off Track",
        "tech_name": "Leslie A.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Davis",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Anna M.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Calvin Johnson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Lauren C.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-10",
        "review_rate": 5.0,
        "customer_name": "Dr. Brent Castro",
        "service_type": "Garage Door Insulation",
        "tech_name": "Matthew M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Richard Fuller",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Garrett C.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Kimberly Luna",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Drew R.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Angela Bowen",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Andrea G.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Michael Banks",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Benjamin H.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Gregory Lester",
        "service_type": "Garage Door Off Track",
        "tech_name": "Robert L.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Jerry Harris",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michelle K.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-04",
        "review_rate": 5.0,
        "customer_name": "Jason Webb",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Melinda A.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.8,
        "customer_name": "Timothy Brandt",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Christina G.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.8,
        "customer_name": "Alan Gould",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kathryn P.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Carol Garcia",
        "service_type": "Residential Garage Door Services",
        "tech_name": "John K.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Robert Barnes",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Joe C.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.9,
        "customer_name": "Johnny Small",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Emily W.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-07",
        "review_rate": 5.0,
        "customer_name": "Tracy Marshall",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Zachary L.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-03",
        "review_rate": 5.0,
        "customer_name": "Mary Hawkins",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jacob W.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.8,
        "customer_name": "Eric Poole",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Alexander B.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Amanda Frazier",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Paul R.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-30",
        "review_rate": 5.0,
        "customer_name": "Christian Wilson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Seth H.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.8,
        "customer_name": "Bethany Avila",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Anthony E.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Steve Carrillo",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Tyrone W.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Sandra Cardenas",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jane R.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.8,
        "customer_name": "Robert Villegas",
        "service_type": "Garage Door Maintenance",
        "tech_name": "James R.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Shannon Thompson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Charles B.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.8,
        "customer_name": "Deanna Fitzpatrick",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Brandi W.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Alyssa Peterson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Matthew L.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-19",
        "review_rate": 5.0,
        "customer_name": "Thomas Barker",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Maria L.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.8,
        "customer_name": "Linda Henson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Matthew M.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Rhonda Randall",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Deborah W.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Jerry Mcdonald",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Ricky J.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-19",
        "review_rate": 5.0,
        "customer_name": "Melanie Gentry",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jessica H.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.9,
        "customer_name": "Ariel Turner",
        "service_type": "Garage Door Services",
        "tech_name": "Kyle B.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-23",
        "review_rate": 5.0,
        "customer_name": "Matthew Kelly",
        "service_type": "Garage Door Opener",
        "tech_name": "Kimberly J.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Anthony Kennedy",
        "service_type": "Garage Door Track Repair",
        "tech_name": "William W.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Paul Graves",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Victor L.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Daniel Lambert",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "James R.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Kendra Webb",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Joel B.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Tracy Ross",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Holly C.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Patrick Cohen",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Reginald M.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-01",
        "review_rate": 5.0,
        "customer_name": "Colin Riley",
        "service_type": "Custom Garage Door Design",
        "tech_name": "John W.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Mark Solis",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Evelyn M.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.8,
        "customer_name": "Dr. Stephen Moreno",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brittany A.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-17",
        "review_rate": 5.0,
        "customer_name": "Darrell Howell",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Sara C.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Valerie Medina",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Elizabeth G.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-27",
        "review_rate": 5.0,
        "customer_name": "Rose Nunez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Renee H.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-05",
        "review_rate": 5.0,
        "customer_name": "Aaron Wells Jr.",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Deanna L.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Roger Wright",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Sharon S.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Victoria Hall",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kelly C.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Cody Sawyer",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Melody T.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.8,
        "customer_name": "Taylor Steele",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Carlos S.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Dawn Valencia",
        "service_type": "Garage Door Insulation",
        "tech_name": "Amanda L.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Sheila Waters",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Lynn W.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Brandon Hoover",
        "service_type": "Garage Door Services",
        "tech_name": "Christopher H.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-04",
        "review_rate": 5.0,
        "customer_name": "Robert Dillon",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Tracy C.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-20",
        "review_rate": 5.0,
        "customer_name": "Edward Evans",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Susan M.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.8,
        "customer_name": "Timothy Nelson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Vickie J.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-21",
        "review_rate": 5.0,
        "customer_name": "Tyler Phillips",
        "service_type": "Garage Door Installation",
        "tech_name": "Ryan R.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-22",
        "review_rate": 5.0,
        "customer_name": "Mary Parsons",
        "service_type": "Garage Door Insulation",
        "tech_name": "Daniel A.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Megan Johnson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Robert R.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Leah Jennings",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Shawn S.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Warren Rivera",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Carmen G.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "Christopher Brown",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jeffrey H.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-20",
        "review_rate": 5.0,
        "customer_name": "Scott Bryant",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "James M.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Vanessa Williams",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Phyllis S.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-30",
        "review_rate": 5.0,
        "customer_name": "Katherine Wolfe",
        "service_type": "Garage Door Opener",
        "tech_name": "Charles C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Michael Pope",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Scott L.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Austin Davidson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Melissa R.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Misty Smith",
        "service_type": "Garage Door Repair",
        "tech_name": "Anthony P.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Troy Kent",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Melissa L.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "William Gonzalez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Daniel H.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.8,
        "customer_name": "Kevin Oneal",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jacob G.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-24",
        "review_rate": 5.0,
        "customer_name": "Kevin Horne",
        "service_type": "Garage Door Installation",
        "tech_name": "Leslie W.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Brandy Gregory",
        "service_type": "Garage Door Installation",
        "tech_name": "Jack N.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-11",
        "review_rate": 5.0,
        "customer_name": "Dr. Joseph Jackson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Patricia M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.8,
        "customer_name": "Anna Ryan",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Scott O.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Pamela Chan",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Lauren B.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Richard Chavez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Terry M.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Tiffany Lindsey",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Paul J.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Connie Barron",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Cory M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-11",
        "review_rate": 5.0,
        "customer_name": "David Jones",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Tara M.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Amy Williams",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Tyler H.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "James Thornton",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Amanda M.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "John House",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Nicholas C.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "David Cain",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Alfred J.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Linda Villa",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Julie J.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-03",
        "review_rate": 5.0,
        "customer_name": "Kathleen Lewis",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jessica M.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Brandon Robbins",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Nathan S.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.8,
        "customer_name": "Ryan Evans",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michael M.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.8,
        "customer_name": "Jason Salinas",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Deanna E.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Christie Henderson",
        "service_type": "Garage Door Services",
        "tech_name": "Stephanie B.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-24",
        "review_rate": 5.0,
        "customer_name": "Daniel Johnston",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michael B.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Jessica Moore",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Melvin C.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.8,
        "customer_name": "Heidi Smith",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Shelley T.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.8,
        "customer_name": "Sydney Acevedo",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jennifer G.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Miguel Sullivan",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Paula L.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Belinda Miller",
        "service_type": "Garage Door Installation",
        "tech_name": "Lisa A.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-19",
        "review_rate": 5.0,
        "customer_name": "Jonathan Andrews",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Manuel W.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-31",
        "review_rate": 5.0,
        "customer_name": "Tony Serrano",
        "service_type": "Garage Door Repair",
        "tech_name": "Anthony C.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Christina Rice",
        "service_type": "Garage Door Insulation",
        "tech_name": "Stacey E.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-14",
        "review_rate": 5.0,
        "customer_name": "Amber Graham MD",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Elizabeth D.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Brandon Valdez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Paul J.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.8,
        "customer_name": "Cory Robinson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Stephanie J.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-12",
        "review_rate": 5.0,
        "customer_name": "Joshua Jennings",
        "service_type": "Garage Door Repair",
        "tech_name": "Sara L.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-22",
        "review_rate": 5.0,
        "customer_name": "Madison Rojas",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michael F.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-18",
        "review_rate": 5.0,
        "customer_name": "Justin Peterson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Sergio A.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Sarah Thomas",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Herbert F.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.8,
        "customer_name": "Lisa Phillips",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jason F.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Adam Wilson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Melissa A.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Cynthia Jefferson",
        "service_type": "Garage Door Services",
        "tech_name": "John H.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.8,
        "customer_name": "Kenneth Santiago",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Ann D.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Melissa Wallace",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Paul M.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.8,
        "customer_name": "Scott Daniels",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Nathan K.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Madison Flores",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Stephanie J.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Christopher Reynolds",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Alexander R.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Amber Smith",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kyle S.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-15",
        "review_rate": 5.0,
        "customer_name": "Keith Frazier",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Cory R.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-29",
        "review_rate": 5.0,
        "customer_name": "Sandra Clark",
        "service_type": "Garage Door Off Track",
        "tech_name": "Rachel H.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-05",
        "review_rate": 5.0,
        "customer_name": "Danielle Matthews",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Douglas H.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.8,
        "customer_name": "Ashley Palmer",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Howard A.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.8,
        "customer_name": "Jennifer Cross",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jamie C.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-16",
        "review_rate": 5.0,
        "customer_name": "David Sheppard",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Robert R.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Amy Alvarado",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Angela R.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Charles Russell",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Mark L.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Freeman",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Laura W.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.9,
        "customer_name": "Juan Reynolds",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Ronald H.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-11",
        "review_rate": 5.0,
        "customer_name": "Alexis Espinoza",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Tammy L.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.8,
        "customer_name": "Jonathan Martinez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Amanda N.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "David Wells",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Leslie H.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Glenn Lindsey",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Richard J.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-29",
        "review_rate": 5.0,
        "customer_name": "Gloria Perez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Leah B.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "Jacob Woodward",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Greg J.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.8,
        "customer_name": "Rebecca Skinner",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Wendy C.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-16",
        "review_rate": 5.0,
        "customer_name": "Ashley Herring",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Michael P.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.8,
        "customer_name": "Allison Brown",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Sean W.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.8,
        "customer_name": "Amy Michael",
        "service_type": "Garage Door Off Track",
        "tech_name": "Leslie M.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Lee",
        "service_type": "Garage Door Repair",
        "tech_name": "Alexander B.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.8,
        "customer_name": "Joseph Graves",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Brian C.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.8,
        "customer_name": "Gary Crosby",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kathryn D.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Kendra Taylor",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kevin M.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Daniel Moore",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Nicholas K.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Mark Chase",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Lori S.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-20",
        "review_rate": 5.0,
        "customer_name": "Tammy Smith",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Diane R.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Andrea Wilson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Anthony J.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "Samantha Martin",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jill J.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.8,
        "customer_name": "Michelle Brandt",
        "service_type": "Garage Door Off Track",
        "tech_name": "James C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.8,
        "customer_name": "Michael Gaines",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Antonio M.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.8,
        "customer_name": "Kelsey Holden",
        "service_type": "Garage Door Off Track",
        "tech_name": "Clayton S.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Thomas Contreras",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Julia G.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Samuel Wright",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Ryan B.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.8,
        "customer_name": "Amanda Kelly",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Matthew S.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Lori Reyes",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Matthew C.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.8,
        "customer_name": "Vanessa Horton",
        "service_type": "Garage Door Track Repair",
        "tech_name": "James V.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Charles Williams",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Anthony S.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Sarah Green",
        "service_type": "Garage Door Repair",
        "tech_name": "Scott L.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Heather Livingston",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Katelyn B.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.8,
        "customer_name": "Catherine Rubio",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Bonnie T.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-19",
        "review_rate": 5.0,
        "customer_name": "Linda Silva",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Mallory T.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-10",
        "review_rate": 5.0,
        "customer_name": "Tiffany Miller",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Joseph A.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-06",
        "review_rate": 5.0,
        "customer_name": "Leah Davis MD",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Mary G.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.8,
        "customer_name": "Holly Frazier MD",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Juan F.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.8,
        "customer_name": "Jennifer Smith",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "William M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Joshua White",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kimberly M.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Amanda Martin",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Casey L.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.8,
        "customer_name": "Mark Sampson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Richard W.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Wendy Barnett",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Tanya C.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.8,
        "customer_name": "Deborah Johnson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Patty M.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-24",
        "review_rate": 5.0,
        "customer_name": "Jack Tyler",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Paul P.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "Mandy Warner",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Makayla C.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.8,
        "customer_name": "Melissa Allen",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Thomas O.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.8,
        "customer_name": "Sherry Lee",
        "service_type": "Garage Door Installation",
        "tech_name": "Ronald R.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-22",
        "review_rate": 5.0,
        "customer_name": "Heather Smith",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Daniel A.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-28",
        "review_rate": 5.0,
        "customer_name": "Jeffery Hughes DDS",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Shaun C.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.8,
        "customer_name": "Chad Sandoval",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Erica D.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.8,
        "customer_name": "Terri Williams",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Christina H.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Peter Phelps",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Daniel A.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Jorge Davis III",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Emily S.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.8,
        "customer_name": "William Ballard",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Ronald R.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Matthew Robinson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Matthew M.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Jennifer Rojas",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Peter F.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-03",
        "review_rate": 5.0,
        "customer_name": "Lisa Holmes",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kimberly R.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Shannon Reed",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jonathan Z.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Jason Schneider",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Samuel V.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Katherine Smith",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Scott C.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Stephen Russell",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Elizabeth R.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.8,
        "customer_name": "Connie Martin",
        "service_type": "Garage Door Off Track",
        "tech_name": "Misty S.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Dr. Jennifer Howard",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Robert E.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Sandra Smith",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Christopher F.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Keith Medina",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Sheri M.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-09",
        "review_rate": 5.0,
        "customer_name": "Joseph Woodard",
        "service_type": "Garage Door Insulation",
        "tech_name": "Nathan J.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Karen Thompson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Greg A.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.8,
        "customer_name": "Brent Harmon",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Brian F.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-12",
        "review_rate": 5.0,
        "customer_name": "William Smith",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Gerald E.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Nicholas Peterson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Steven A.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-14",
        "review_rate": 5.0,
        "customer_name": "Laura White",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Matthew M.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Sabrina Rodriguez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Ricky D.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Kathleen Jackson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kimberly C.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Pamela Goodman",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Justin N.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Kurt Turner",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Robin D.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.8,
        "customer_name": "Justin Jones",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Nicole M.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-04",
        "review_rate": 5.0,
        "customer_name": "Adam Shaw",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Heidi S.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.8,
        "customer_name": "Eric Trevino",
        "service_type": "Garage Door Services",
        "tech_name": "Sean L.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Jesse Hudson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Ernest M.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-14",
        "review_rate": 5.0,
        "customer_name": "Dana Curry",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Brian T.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Joshua Cruz",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Daniel D.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-30",
        "review_rate": 5.0,
        "customer_name": "Tina Santos",
        "service_type": "Garage Door Off Track",
        "tech_name": "Michele S.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.8,
        "customer_name": "Samantha Gallegos",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Laura C.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-05",
        "review_rate": 5.0,
        "customer_name": "Juan Wallace",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Peter C.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-24",
        "review_rate": 5.0,
        "customer_name": "Erin Hernandez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Dalton H.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Thomas Hester",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "John C.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.8,
        "customer_name": "Linda Boyd",
        "service_type": "Garage Door Repair",
        "tech_name": "Willie G.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Dylan Gibbs",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kyle M.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Jillian Hoffman",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Leslie P.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Terry Burnett",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Billy G.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.8,
        "customer_name": "Jason Williams",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Melissa B.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Benjamin Thompson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Amber F.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Eddie Sosa",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jesse T.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Matthew Gonzalez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Misty G.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.8,
        "customer_name": "Melissa Lee",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jonathan M.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-05",
        "review_rate": 5.0,
        "customer_name": "Walter Chandler",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Curtis L.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.8,
        "customer_name": "Steve Conley",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Scott J.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-19",
        "review_rate": 5.0,
        "customer_name": "Paige Johnson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Melissa W.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-06",
        "review_rate": 5.0,
        "customer_name": "Ronald Ellison",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Carolyn G.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Michelle Cruz",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kelly D.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-15",
        "review_rate": 5.0,
        "customer_name": "Alexander Christensen",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Benjamin T.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Monica Thompson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jonathan G.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Connor Garcia",
        "service_type": "Garage Door Services",
        "tech_name": "Brittany C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-07",
        "review_rate": 5.0,
        "customer_name": "Victor Booth",
        "service_type": "Garage Door Services",
        "tech_name": "Karen F.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Alexis Roberts",
        "service_type": "Garage Door Off Track",
        "tech_name": "David C.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Daniel Chang",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Pamela L.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-13",
        "review_rate": 5.0,
        "customer_name": "James Lopez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Richard G.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.8,
        "customer_name": "Patrick Ellis",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Eric R.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Marc James",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "John P.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Shannon Moore",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Rebecca H.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.8,
        "customer_name": "Sonia Jimenez",
        "service_type": "Garage Door Opener",
        "tech_name": "Shannon C.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.8,
        "customer_name": "Andrew Gray",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Patricia W.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.8,
        "customer_name": "Mary Wong",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christina H.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Melissa Green",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jason M.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Richard Day",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Katie W.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-27",
        "review_rate": 5.0,
        "customer_name": "Julie Banks",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Paula C.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "Jodi Shaw",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Thomas F.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "George Hayden",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Anne J.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Laura Mitchell",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Melanie C.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Sarah Price",
        "service_type": "Garage Door Installation",
        "tech_name": "Cheryl R.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-02",
        "review_rate": 5.0,
        "customer_name": "Cody Hamilton",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Alexander B.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Wendy Turner",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Steven H.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Eric Brown",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Teresa H.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Autumn Miller",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Anthony M.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Karen Davis",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jon H.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Daniel Nelson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Herbert M.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.8,
        "customer_name": "Jenna Donaldson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Elizabeth G.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.8,
        "customer_name": "Lisa Hill",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jeremy A.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.8,
        "customer_name": "Justin Brown",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Joe P.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.8,
        "customer_name": "Jacqueline Gonzales",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Glen S.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-04",
        "review_rate": 5.0,
        "customer_name": "Teresa Douglas MD",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Adam F.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Richard Gray",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Patrick H.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Sarah Greene",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Elizabeth R.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "James Dunn",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Alexandra S.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Denise King",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Danny B.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-10",
        "review_rate": 5.0,
        "customer_name": "Kevin House",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Katherine M.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-14",
        "review_rate": 5.0,
        "customer_name": "Michelle Lewis",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Steven J.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.8,
        "customer_name": "Holly Phillips",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Ronald T.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Rachel Henderson",
        "service_type": "Garage Door Services",
        "tech_name": "Lauren R.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "James Bowen",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Mark H.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Tyler Berger DDS",
        "service_type": "Garage Door Insulation",
        "tech_name": "Joseph P.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.9,
        "customer_name": "Christopher Smith",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Brianna S.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Victor Day",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Erin L.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Jennifer Maldonado",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brittany W.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Teresa Wilson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Morgan C.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-27",
        "review_rate": 5.0,
        "customer_name": "John Norris",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Nicole P.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.8,
        "customer_name": "Heather Reid",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Dawn F.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.8,
        "customer_name": "Dr. Marie Alvarez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Keith B.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Michael Brennan",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Paul H.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Patricia Davis",
        "service_type": "Garage Door Off Track",
        "tech_name": "Antonio G.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-04",
        "review_rate": 5.0,
        "customer_name": "Andres Moran",
        "service_type": "Garage Door Off Track",
        "tech_name": "Debra S.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Cassandra Dyer",
        "service_type": "Garage Door Installation",
        "tech_name": "Derek G.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Duane Nixon",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Keith M.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Timothy Burke",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Laura S.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-16",
        "review_rate": 5.0,
        "customer_name": "John Grimes",
        "service_type": "Garage Door Repair",
        "tech_name": "Kimberly C.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Nathan Rivera",
        "service_type": "Garage Door Insulation",
        "tech_name": "John R.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.8,
        "customer_name": "Jacob Mills",
        "service_type": "Garage Door Repair",
        "tech_name": "Mary W.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.8,
        "customer_name": "Lisa Rosales",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Nicholas R.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Andrew Knight",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Stefanie C.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Katie Stafford",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Dana C.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.8,
        "customer_name": "Mrs. Michelle Thomas",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Paula T.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.9,
        "customer_name": "Mary Murphy",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Mark R.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-18",
        "review_rate": 5.0,
        "customer_name": "Cathy Weaver",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Scott F.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Todd Elliott",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Christopher O.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.8,
        "customer_name": "Taylor Williams",
        "service_type": "Garage Door Services",
        "tech_name": "Renee J.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Rachel Alexander",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jacqueline M.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-30",
        "review_rate": 5.0,
        "customer_name": "Manuel Gonzales",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "John B.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "John Chambers",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Catherine H.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Dan Li",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Emily P.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.8,
        "customer_name": "Tracy Skinner",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Bryce W.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.8,
        "customer_name": "Kristina Lee",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michael J.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-31",
        "review_rate": 5.0,
        "customer_name": "Kimberly Kelly",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Carla C.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.8,
        "customer_name": "Charles Davis",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Maria L.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.8,
        "customer_name": "Brett Zhang",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Susan J.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Christopher Miller",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jill R.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.8,
        "customer_name": "Eileen Williamson",
        "service_type": "Garage Door Repair",
        "tech_name": "Lauren W.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Mary Dickson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jason M.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Jo Wright",
        "service_type": "Garage Door Insulation",
        "tech_name": "Tracy S.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.8,
        "customer_name": "Jill Klein",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Ashley E.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Erica Miller",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Allison S.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Howard Arroyo",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Stephanie R.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Stephanie Rodriguez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Leah A.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Anthony Morales",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Brian B.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-27",
        "review_rate": 5.0,
        "customer_name": "Courtney King",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Melissa T.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-28",
        "review_rate": 5.0,
        "customer_name": "Eric Kim",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Lauren H.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.8,
        "customer_name": "Christopher Cochran",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Felicia L.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Steven Howard",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Tyler M.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.8,
        "customer_name": "Robert Jackson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Noah C.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.8,
        "customer_name": "Danielle Crawford",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "John W.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-03",
        "review_rate": 5.0,
        "customer_name": "Jordan Day",
        "service_type": "Garage Door Off Track",
        "tech_name": "Monica M.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "Nicole Moreno",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Marcus E.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.8,
        "customer_name": "Lori Bell",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Michael J.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "William Osborne",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Erica C.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.8,
        "customer_name": "Anna Ortiz",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Samantha R.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-11",
        "review_rate": 5.0,
        "customer_name": "Jeremiah Brown",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Melanie R.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Kelly Greene",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Amanda M.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-17",
        "review_rate": 5.0,
        "customer_name": "Anna Owen",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michelle B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-24",
        "review_rate": 5.0,
        "customer_name": "Ralph Smith",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Michael B.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Steven Bell",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Gail W.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-18",
        "review_rate": 5.0,
        "customer_name": "Kelli Schneider",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Carla S.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Gina Arellano",
        "service_type": "Garage Door Repair",
        "tech_name": "Deborah W.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-11",
        "review_rate": 5.0,
        "customer_name": "Richard Compton",
        "service_type": "Garage Door Repair",
        "tech_name": "Tina M.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-07",
        "review_rate": 5.0,
        "customer_name": "Kenneth Thomas",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Sandra E.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.8,
        "customer_name": "Madison Black",
        "service_type": "Garage Door Services",
        "tech_name": "Larry R.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Cory Leonard",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Timothy R.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Sarah Hernandez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Tyler W.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.8,
        "customer_name": "Jesus Brown",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Todd M.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-10",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Davis",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Roger W.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.8,
        "customer_name": "Carrie Rios",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Brady C.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "Danielle Shelton",
        "service_type": "Garage Door Installation",
        "tech_name": "Ricardo A.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.8,
        "customer_name": "Zachary Perez",
        "service_type": "Garage Door Opener",
        "tech_name": "Sarah M.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Heather Wood",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Erika B.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.8,
        "customer_name": "Debra Moore",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Sherri J.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "Sara Fuentes",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Andrew J.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Robert Alexander",
        "service_type": "Garage Door Installation",
        "tech_name": "Mathew D.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-25",
        "review_rate": 5.0,
        "customer_name": "Christine Wood",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Theresa D.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "Bradley Fernandez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Austin C.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.8,
        "customer_name": "Samantha Yoder",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Marie P.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Michael Jones",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Patricia H.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Rebecca Bruce",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Joanne F.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Nicholas Hoover",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Alice B.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.8,
        "customer_name": "Morgan Adams",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jessica G.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.8,
        "customer_name": "Abigail Hebert",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Matthew S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Jamie Escobar",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Mary T.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Vanessa Huffman",
        "service_type": "Garage Door Off Track",
        "tech_name": "Allen J.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-07",
        "review_rate": 5.0,
        "customer_name": "Cynthia Anderson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Brooke R.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Joshua Valencia",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Amanda G.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-20",
        "review_rate": 5.0,
        "customer_name": "Samantha Stewart",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Tiffany M.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Samantha Francis",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Olivia M.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.8,
        "customer_name": "Michael Parks",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Donald M.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.8,
        "customer_name": "Henry Fox",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jeffery P.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Kyle Robertson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Brooke M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.8,
        "customer_name": "Thomas Mcneil",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Thomas L.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Joseph Adkins",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jesse M.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.8,
        "customer_name": "Rebecca Cooper",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Ronald R.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "Deborah Sanchez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Amanda J.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.8,
        "customer_name": "Brenda Ball",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kyle P.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-03",
        "review_rate": 5.0,
        "customer_name": "Christine Fuller",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "David J.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-22",
        "review_rate": 5.0,
        "customer_name": "Marie Cardenas",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Rachel F.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-21",
        "review_rate": 5.0,
        "customer_name": "Christopher Arnold",
        "service_type": "Garage Door Installation",
        "tech_name": "Derrick G.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Sara Contreras",
        "service_type": "Garage Door Insulation",
        "tech_name": "Monica S.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Jessica French",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Christina P.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.8,
        "customer_name": "James May",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Tiffany A.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Daniel Simpson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Daniel W.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.8,
        "customer_name": "Ebony Shepherd",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jose A.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.8,
        "customer_name": "Adam Chambers",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jacob P.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-13",
        "review_rate": 5.0,
        "customer_name": "Diana Nunez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Misty M.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.8,
        "customer_name": "Gary Gutierrez",
        "service_type": "Garage Door Repair",
        "tech_name": "Tyrone P.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Brian Burton",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Rachael P.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.8,
        "customer_name": "Rebecca Reyes",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "April A.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Aaron Paul",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Roy M.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.8,
        "customer_name": "Mary Meyers",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Daniel G.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.8,
        "customer_name": "Kayla Summers",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Heather B.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.8,
        "customer_name": "Cristina Thompson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Robert R.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.8,
        "customer_name": "Tracie Garcia",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Michelle C.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.8,
        "customer_name": "Ana Mendez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Amanda M.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.8,
        "customer_name": "Erica Vazquez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Marissa B.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.8,
        "customer_name": "Cynthia Conway MD",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Heidi W.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.8,
        "customer_name": "Terry Salazar",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Ronald B.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Charles Hall",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jasmin O.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Victor Weaver",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Crystal W.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Marc Wallace",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Desiree J.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-11",
        "review_rate": 5.0,
        "customer_name": "Susan Weaver",
        "service_type": "Garage Door Insulation",
        "tech_name": "Russell P.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-08",
        "review_rate": 5.0,
        "customer_name": "Cassandra Barrett",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kenneth H.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-13",
        "review_rate": 5.0,
        "customer_name": "Gabriela Sellers",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Shannon T.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Kyle Howard",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Rick S.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-07",
        "review_rate": 5.0,
        "customer_name": "Frank Nicholson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Christopher L.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Rasmussen",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Noah S.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-12",
        "review_rate": 5.0,
        "customer_name": "John Butler",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jamie H.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Luis Simmons",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Chase L.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.8,
        "customer_name": "Erin Mitchell",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Russell W.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Joseph Davis",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Lee R.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Kathy Lewis",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jon W.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Dwayne Mccarthy",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Justin C.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Pamela West",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Richard M.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "Courtney Tucker",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Emily Y.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Karen Hoffman",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Alex B.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-07",
        "review_rate": 5.0,
        "customer_name": "Samuel Rangel",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Rachel P.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Janet Davis",
        "service_type": "Garage Door Repair",
        "tech_name": "Melissa H.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-15",
        "review_rate": 5.0,
        "customer_name": "Alyssa Clay",
        "service_type": "Garage Door Insulation",
        "tech_name": "Anna R.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-01",
        "review_rate": 5.0,
        "customer_name": "Anne Larson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Donald S.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Lori Garcia",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Joseph L.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Jennifer Brown",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Antonio S.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Megan Scott",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Lisa M.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.8,
        "customer_name": "Paula Owens",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Melanie L.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-17",
        "review_rate": 5.0,
        "customer_name": "Anna Ramos",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Ryan N.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-07",
        "review_rate": 5.0,
        "customer_name": "Jeff Jackson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Wendy M.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Larry Ward",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Erika S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Aaron Parker",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jeffery S.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.8,
        "customer_name": "Clayton Sherman",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Sharon D.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-25",
        "review_rate": 5.0,
        "customer_name": "Jacob Mccullough",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Elizabeth F.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Charles Beard",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Anthony S.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.9,
        "customer_name": "Marie Turner",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Laurie T.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Margaret Fletcher",
        "service_type": "Garage Door Insulation",
        "tech_name": "Wendy C.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Lindsey Morgan",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Linda W.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Christopher Baker",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Yolanda A.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Teresa Gonzalez",
        "service_type": "Garage Door Services",
        "tech_name": "Ryan S.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Charles Joyce",
        "service_type": "Garage Door Insulation",
        "tech_name": "Taylor B.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Erica Herring",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "James F.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Patrick Anderson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kelsey M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-22",
        "review_rate": 5.0,
        "customer_name": "Yolanda Conner",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "David M.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Michael Jones",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christina A.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Robin Perez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Erin P.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "Daniel Todd",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Ricardo W.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-30",
        "review_rate": 5.0,
        "customer_name": "Colton Ward",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Mary B.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-11",
        "review_rate": 5.0,
        "customer_name": "Ashley Jimenez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Matthew B.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Debbie White",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Alan C.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Linda Booker",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Gerald C.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Wendy Cook",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michele R.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Jordan Hammond",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Justin F.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.8,
        "customer_name": "Shannon Espinoza",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Emma S.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.8,
        "customer_name": "Cory Hawkins",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Courtney R.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-20",
        "review_rate": 5.0,
        "customer_name": "Ariel Fisher",
        "service_type": "Garage Door Installation",
        "tech_name": "Chris I.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Taylor Bates",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Ryan V.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-13",
        "review_rate": 5.0,
        "customer_name": "Patrick Valentine",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Robert G.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.8,
        "customer_name": "Robin Young",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Vicki M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Best DDS",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Crystal M.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Christopher Mack",
        "service_type": "Garage Door Opener",
        "tech_name": "Hailey T.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Terry Kelly",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Mark D.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.8,
        "customer_name": "Brandon Gonzalez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Sherry S.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.8,
        "customer_name": "Jacob Myers",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Anthony T.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Leslie Rivera",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Heather H.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-09",
        "review_rate": 5.0,
        "customer_name": "Rebecca Tate",
        "service_type": "Garage Door Insulation",
        "tech_name": "Misty A.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Lori Perry",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Beth D.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Emily Palmer",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Brianna C.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.8,
        "customer_name": "Cindy Robertson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Patrick T.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.8,
        "customer_name": "Lisa Estrada",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Marvin S.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.8,
        "customer_name": "Jason Rivera",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Tara G.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-29",
        "review_rate": 5.0,
        "customer_name": "Jose Ward",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Lauren R.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Robert Powers",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jennifer M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Monique Wright",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Elizabeth S.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-26",
        "review_rate": 5.0,
        "customer_name": "Kevin Kelley",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Amanda S.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Anderson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Gina B.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.9,
        "customer_name": "William Williams",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Robert D.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Emily Smith",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Brandi R.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-25",
        "review_rate": 5.0,
        "customer_name": "Alexander White",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jennifer C.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Leslie Marquez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Adam R.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Bowman",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Abigail W.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-01",
        "review_rate": 5.0,
        "customer_name": "James Edwards",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Rebecca C.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.8,
        "customer_name": "Sara Simmons",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Diane S.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Leonard Gonzales",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Susan W.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Heidi Koch",
        "service_type": "Garage Door Insulation",
        "tech_name": "Teresa H.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Meagan Edwards DVM",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Sheila S.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-11",
        "review_rate": 5.0,
        "customer_name": "Latasha Hanson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jennifer K.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Miss Grace Hampton",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Cynthia H.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-13",
        "review_rate": 5.0,
        "customer_name": "Marvin Davis DDS",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Melissa C.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Christopher Scott",
        "service_type": "Garage Door Services",
        "tech_name": "Luis B.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Jay Wright",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kimberly J.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Alexis Harvey",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Stanley E.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Fletcher",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Aaron M.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Daniel Knight",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Timothy M.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-02",
        "review_rate": 5.0,
        "customer_name": "Jeffery Webb",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Darryl S.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Kenneth Jones",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Eric L.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Jay Hess",
        "service_type": "Garage Door Off Track",
        "tech_name": "Shelia F.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.9,
        "customer_name": "Russell Lowe",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jennifer S.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.8,
        "customer_name": "Heather Moore",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Lori W.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.8,
        "customer_name": "Teresa Webb",
        "service_type": "Garage Door Insulation",
        "tech_name": "Adrian J.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-24",
        "review_rate": 5.0,
        "customer_name": "Stephen Cervantes",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Adam H.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.8,
        "customer_name": "Joseph Rodriguez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Alexandra W.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Adam Singh",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Erik R.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-16",
        "review_rate": 5.0,
        "customer_name": "Kayla Dixon",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Hannah H.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Jamie Garcia",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Andrea C.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Patrick Johnson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jacqueline T.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Steven Myers",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kevin S.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Michelle Reynolds",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Larry G.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-21",
        "review_rate": 5.0,
        "customer_name": "Zachary Johnson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Charles V.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Lynn Conley",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Craig B.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Linda Robinson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Nancy G.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-12",
        "review_rate": 5.0,
        "customer_name": "Joseph Davidson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "James F.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Jennifer Casey",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Bonnie G.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.8,
        "customer_name": "Amy Gray",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Andrew H.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.8,
        "customer_name": "Carlos Mckinney",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Katelyn B.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-14",
        "review_rate": 5.0,
        "customer_name": "Brent Price Jr.",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Natalie H.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Maria Merritt",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jessica P.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-27",
        "review_rate": 5.0,
        "customer_name": "Shelby Johnson",
        "service_type": "Garage Door Installation",
        "tech_name": "Michael G.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Ian Armstrong",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Rebecca C.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-17",
        "review_rate": 5.0,
        "customer_name": "Jennifer Charles",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jason S.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.8,
        "customer_name": "Diana Mercado",
        "service_type": "Garage Door Off Track",
        "tech_name": "Marissa S.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Derrick Ramirez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Ann S.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.8,
        "customer_name": "Bradley Martinez",
        "service_type": "Garage Door Off Track",
        "tech_name": "John B.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Adrian Hernandez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Robert J.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "Jacqueline Nelson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Nicholas H.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Katherine Collier",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Andrea O.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-13",
        "review_rate": 5.0,
        "customer_name": "Beverly Watson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Leslie K.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-27",
        "review_rate": 5.0,
        "customer_name": "Alec Rodriguez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jacob S.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Theresa Griffin MD",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Daniel A.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.8,
        "customer_name": "Heather Harris MD",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kathleen W.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "John Garza",
        "service_type": "Garage Door Opener",
        "tech_name": "Albert R.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-30",
        "review_rate": 5.0,
        "customer_name": "Jason Owens",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Benjamin W.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.8,
        "customer_name": "Latoya Williams",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Melissa H.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Andrew Walls",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Aaron T.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Bryan Abbott",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kevin W.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.8,
        "customer_name": "Brittany Davis",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Teresa J.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.9,
        "customer_name": "Tommy Cummings",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Grace D.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "Erika Snyder",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Christopher L.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Alexandra Solomon",
        "service_type": "Garage Door Off Track",
        "tech_name": "Elizabeth H.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Kathryn Juarez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Lisa K.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Patricia Smith MD",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Cindy H.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Rebecca Stephens",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Cathy S.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-08",
        "review_rate": 5.0,
        "customer_name": "Michelle Barajas",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Leonard L.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-08",
        "review_rate": 5.0,
        "customer_name": "William Wheeler",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Barbara R.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-14",
        "review_rate": 5.0,
        "customer_name": "Tim Smith",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Victor V.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-02",
        "review_rate": 5.0,
        "customer_name": "Kenneth Anderson",
        "service_type": "Garage Door Services",
        "tech_name": "Angelica B.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Timothy Williams",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Preston N.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Robin Acevedo",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Shelby C.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-21",
        "review_rate": 5.0,
        "customer_name": "Mrs. Victoria Burton",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kimberly N.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "Megan Parker",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Charles S.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.8,
        "customer_name": "William Davis",
        "service_type": "Garage Door Repair",
        "tech_name": "Karen M.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.8,
        "customer_name": "Cody Dunn",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Sarah S.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Virginia Walker",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Daniel H.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-16",
        "review_rate": 5.0,
        "customer_name": "Kylie Henry",
        "service_type": "Garage Door Opener",
        "tech_name": "Stacey C.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-01",
        "review_rate": 5.0,
        "customer_name": "Kevin Todd",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Amanda J.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Karina Kelley",
        "service_type": "Garage Door Services",
        "tech_name": "Jorge M.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Carol Miller",
        "service_type": "Garage Door Opener",
        "tech_name": "Adam W.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "Edward Williams",
        "service_type": "Garage Door Opener",
        "tech_name": "Shane L.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Whitney Hall",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Timothy M.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.8,
        "customer_name": "Nicholas Sutton",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Bonnie F.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Jonathan Hull",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Elijah J.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-07",
        "review_rate": 5.0,
        "customer_name": "Karen Phillips",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Elizabeth M.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Casey Robertson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Mary S.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Linda Valdez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brandon G.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Kathleen Lowe",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Michelle W.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Victoria Sparks",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Meghan O.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.8,
        "customer_name": "Seth Johnson",
        "service_type": "Garage Door Repair",
        "tech_name": "Evan L.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-09-29",
        "review_rate": 5.0,
        "customer_name": "Donna Chandler",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Shelby W.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Joshua Smith",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Dustin C.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Mark Mason",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Mark R.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Rachel Ballard",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Barbara L.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Brandi Hunter",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Shannon B.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.8,
        "customer_name": "Amy Fernandez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jessica C.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Jacob Rodriguez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Nicholas B.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.8,
        "customer_name": "Steven Vance",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Deborah J.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Ryan Butler",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brendan S.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.8,
        "customer_name": "Sherri Robinson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Amber E.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Renee Thomas",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jennifer S.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Francisco Morris",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Katherine M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Kelly",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "William I.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Jordan Oneill",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Bradley M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-17",
        "review_rate": 5.0,
        "customer_name": "Chris Leonard",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Oscar J.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-25",
        "review_rate": 5.0,
        "customer_name": "Mackenzie Mccarthy",
        "service_type": "Garage Door Off Track",
        "tech_name": "Daniel D.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-25",
        "review_rate": 5.0,
        "customer_name": "Amy Goodwin",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jason T.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Monique Baker",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "John F.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Lindsey Huff MD",
        "service_type": "Garage Door Repair",
        "tech_name": "Tiffany T.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-08",
        "review_rate": 5.0,
        "customer_name": "Joshua Brock",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kristina C.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "James Hansen",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Ariel B.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Daniel Walters",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "James W.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-01",
        "review_rate": 5.0,
        "customer_name": "Linda White",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Phillip H.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.8,
        "customer_name": "Sean Johnson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Michaela W.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.8,
        "customer_name": "Michelle Weaver",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Charles M.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Brittany Kidd",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Evan B.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Valerie Hudson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kathy B.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-01",
        "review_rate": 5.0,
        "customer_name": "Wendy Anderson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jessica H.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.8,
        "customer_name": "Cameron Chan",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jack L.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Mallory Morgan",
        "service_type": "Garage Door Opener",
        "tech_name": "Nicholas C.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Jesse Dean",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Brent B.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Jill Reyes",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kristopher S.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-27",
        "review_rate": 5.0,
        "customer_name": "Katie Mcgee",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Donna H.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-24",
        "review_rate": 5.0,
        "customer_name": "Rita Blackwell",
        "service_type": "Garage Door Services",
        "tech_name": "Lisa S.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.8,
        "customer_name": "Timothy White",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Joseph R.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.8,
        "customer_name": "April Sanders",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jennifer C.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Laura Ruiz",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Tyler F.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-05",
        "review_rate": 5.0,
        "customer_name": "Devin Nichols MD",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Nathaniel C.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.8,
        "customer_name": "Aaron Franklin",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Vincent D.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.8,
        "customer_name": "Amy Adams",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Joseph F.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Allison Ward",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Ronnie W.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.8,
        "customer_name": "Aaron Garcia",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Erica K.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-19",
        "review_rate": 5.0,
        "customer_name": "Leslie Stevens",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Alexandra G.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.8,
        "customer_name": "Lisa Parrish",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kayla K.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Madison Miller",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Sara G.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "April Jensen",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Nathaniel G.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-24",
        "review_rate": 5.0,
        "customer_name": "Tammy Fox",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jennifer D.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-18",
        "review_rate": 5.0,
        "customer_name": "Nancy Scott",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Christine W.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-09",
        "review_rate": 5.0,
        "customer_name": "William Gomez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Timothy M.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Kevin Gibbs",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Clinton N.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "David Harvey",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Tyler H.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.8,
        "customer_name": "David Hudson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jenna H.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Charlene Kline",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Alfred W.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-26",
        "review_rate": 5.0,
        "customer_name": "William Stein",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brittany F.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.8,
        "customer_name": "Daniel Bell",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kara W.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Jasmin Stevens",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Randy L.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Christian Lowery",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Rachael G.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-13",
        "review_rate": 5.0,
        "customer_name": "Sara Best",
        "service_type": "Garage Door Off Track",
        "tech_name": "Ryan B.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-30",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Galvan",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Amanda K.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.8,
        "customer_name": "David Higgins",
        "service_type": "Residential Garage Door Services",
        "tech_name": "William M.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-02",
        "review_rate": 5.0,
        "customer_name": "Michael Harvey",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "John G.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.8,
        "customer_name": "Hayley Martinez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kyle W.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Anita Gonzalez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Maria P.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-09-27",
        "review_rate": 5.0,
        "customer_name": "Keith Donovan",
        "service_type": "Garage Door Repair",
        "tech_name": "Michael P.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-18",
        "review_rate": 5.0,
        "customer_name": "Sherry Miller",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Edward L.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Scott Riley",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Andrew L.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Melissa Wilson",
        "service_type": "Garage Door Opener",
        "tech_name": "Kimberly H.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-02",
        "review_rate": 5.0,
        "customer_name": "Tiffany Orozco",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Michael W.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "Jose Gilbert",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Travis W.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.8,
        "customer_name": "Christopher Martin",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Megan A.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Jason Vaughn",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Sharon S.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.8,
        "customer_name": "Shannon White",
        "service_type": "Garage Door Off Track",
        "tech_name": "David M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-25",
        "review_rate": 5.0,
        "customer_name": "Victoria Fox",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Joy T.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Sheri Foster",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jessica H.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Mary Garcia",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Justin J.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-19",
        "review_rate": 5.0,
        "customer_name": "Kyle Cline",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Savannah R.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Kathryn Wong",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Erik N.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Annette Reed",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kathryn L.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Anna Fields",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Donald M.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Richard Frye",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jean D.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "Matthew Diaz",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Teresa H.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.8,
        "customer_name": "Kerry Williams",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Sara D.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Timothy Pace",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Travis M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-07",
        "review_rate": 5.0,
        "customer_name": "Christine Dunn",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Connor H.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-22",
        "review_rate": 5.0,
        "customer_name": "Brenda Ortiz",
        "service_type": "Garage Door Off Track",
        "tech_name": "Steven W.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.8,
        "customer_name": "Jennifer Smith",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Mark L.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Laura Morales",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Stephen G.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Jennifer Grant MD",
        "service_type": "Garage Door Repair",
        "tech_name": "Adam J.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Stephanie Hall",
        "service_type": "Garage Door Services",
        "tech_name": "Jacqueline L.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "Christopher Goodwin",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Paul S.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Francisco Medina",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Matthew P.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.8,
        "customer_name": "Marcus Hansen",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Hannah P.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "James Thomas",
        "service_type": "Garage Door Installation",
        "tech_name": "Karen G.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-27",
        "review_rate": 5.0,
        "customer_name": "Joshua Edwards",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Diana C.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Nicholas Ochoa",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michael M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.8,
        "customer_name": "David White",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Randall W.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-27",
        "review_rate": 5.0,
        "customer_name": "Deanna Blankenship",
        "service_type": "Garage Door Opener",
        "tech_name": "Penny P.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-09-28",
        "review_rate": 5.0,
        "customer_name": "Allen Washington",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Samantha M.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-22",
        "review_rate": 5.0,
        "customer_name": "Angela Ryan",
        "service_type": "Garage Door Installation",
        "tech_name": "Shelby B.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "Ms. Patricia Hill",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Ryan D.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Mark Lee",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Peter R.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Jennifer Hart",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Brittney H.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-05",
        "review_rate": 5.0,
        "customer_name": "Shane Wright",
        "service_type": "Garage Door Repair",
        "tech_name": "Daniel M.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Lynn Giles",
        "service_type": "Garage Door Off Track",
        "tech_name": "Christina H.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Christopher Dillon",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michael Y.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Patrick Baird Jr.",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Stacy M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-04",
        "review_rate": 5.0,
        "customer_name": "Eric Mcmillan",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Brittany C.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Bryan Clark",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Stephanie K.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.9,
        "customer_name": "Jessica Baker MD",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Christian B.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-11",
        "review_rate": 5.0,
        "customer_name": "Scott Thompson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Ashley W.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.8,
        "customer_name": "Andrew Davis",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Anna G.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Regina Walsh",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Nathan L.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Jason Collins",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Vanessa C.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Connor Pitts",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Robert C.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Sheri Bennett",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jessica D.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.8,
        "customer_name": "Danielle Benton",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Adam L.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Lynn Pena",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Steven Y.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-21",
        "review_rate": 5.0,
        "customer_name": "Lisa Harris",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Garrett S.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-08",
        "review_rate": 5.0,
        "customer_name": "Brian Watson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Mary R.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Sophia Blackwell",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Frank O.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.8,
        "customer_name": "Patricia Weaver",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jessica G.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.8,
        "customer_name": "Gary Reyes",
        "service_type": "Garage Door Opener",
        "tech_name": "Jimmy N.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Dr. Michael Sanchez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Gabriel G.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-22",
        "review_rate": 5.0,
        "customer_name": "Greg Hughes",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Manuel J.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Donald Carter",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Sharon H.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.9,
        "customer_name": "Mackenzie Sanders",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Sierra A.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-07",
        "review_rate": 5.0,
        "customer_name": "Larry Roberts",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "John H.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.8,
        "customer_name": "Adam Mitchell",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Phillip R.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Rebecca Phillips",
        "service_type": "Garage Door Installation",
        "tech_name": "Janet P.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Albert Lane",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kathleen R.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-03",
        "review_rate": 5.0,
        "customer_name": "Erika Jones",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Vanessa M.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-19",
        "review_rate": 5.0,
        "customer_name": "Brittany Taylor",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Matthew B.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.8,
        "customer_name": "Kevin Boyd",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Patrick W.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Nathaniel Graves",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Robin W.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-29",
        "review_rate": 5.0,
        "customer_name": "Catherine Stanley",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Thomas C.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Amanda Lopez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Andrew C.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "James Perez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Brandon H.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Kelsey Downs",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Shelly W.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-12",
        "review_rate": 5.0,
        "customer_name": "Michele Perez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Richard M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-27",
        "review_rate": 5.0,
        "customer_name": "Anthony Davis",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Cody A.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Andrew Fisher",
        "service_type": "Garage Door Services",
        "tech_name": "Taylor Y.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.8,
        "customer_name": "Jennifer Barker",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michelle R.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.8,
        "customer_name": "David Parker",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Bobby R.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.8,
        "customer_name": "Julia Garcia MD",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kevin C.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.8,
        "customer_name": "Amber Johnson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kyle M.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Shannon Moran",
        "service_type": "Garage Door Track Repair",
        "tech_name": "John B.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-07",
        "review_rate": 5.0,
        "customer_name": "Mr. Joseph Barker",
        "service_type": "Custom Garage Door Design",
        "tech_name": "David P.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.8,
        "customer_name": "Paul Smith",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Roy B.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Amy Foster",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Tony H.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Andrew Thomas",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Ashley S.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.8,
        "customer_name": "Colin Harris",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Patricia C.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Ronald Johnson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Sergio G.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-10",
        "review_rate": 5.0,
        "customer_name": "William Hubbard",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Joshua K.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.8,
        "customer_name": "Jordan Myers",
        "service_type": "Garage Door Maintenance",
        "tech_name": "David A.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Jason Davis",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Shawn R.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Emily Stephens",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Michelle D.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.9,
        "customer_name": "Matthew Horn",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Yvonne H.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-16",
        "review_rate": 5.0,
        "customer_name": "Caroline Wise",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Nathan O.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Felicia Wallace",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "William C.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.8,
        "customer_name": "Annette Gregory",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Mark S.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "William Patton",
        "service_type": "Garage Door Installation",
        "tech_name": "Meghan B.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.8,
        "customer_name": "Colleen Cain",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Andrea B.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.8,
        "customer_name": "Julie Good",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Curtis H.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Tiffany Smith",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Angel K.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.8,
        "customer_name": "Carrie Douglas",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Susan R.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Joshua Wilson",
        "service_type": "Garage Door Repair",
        "tech_name": "Kimberly H.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Natalie Hall",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Sally W.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Anita Rodriguez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Rose F.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Shane Thomas",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Ashley O.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Mr. Clifford Hardin",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "James F.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Samantha Sweeney",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brian W.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Victoria Glenn",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Daniel H.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.8,
        "customer_name": "Jonathan Lynch",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Sarah S.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-11",
        "review_rate": 5.0,
        "customer_name": "Samuel Diaz",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Karen R.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.9,
        "customer_name": "Heather Bowen MD",
        "service_type": "Garage Door Opener",
        "tech_name": "Angela C.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Malik House",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Eric H.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-15",
        "review_rate": 5.0,
        "customer_name": "Kendra Quinn",
        "service_type": "Garage Door Services",
        "tech_name": "Billy G.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Jaclyn Brown",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Samantha C.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Timothy Cox",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Heather M.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Julian Carr",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jacqueline S.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Dr. Steven Marquez MD",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Marvin E.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Joseph Sims",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Lisa C.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Justin Wright",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Elizabeth P.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Courtney Murphy",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Patrick W.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Joe Scott",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Courtney K.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-15",
        "review_rate": 5.0,
        "customer_name": "Clayton Sutton",
        "service_type": "Garage Door Installation",
        "tech_name": "Joy M.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-13",
        "review_rate": 5.0,
        "customer_name": "Nathan Brown",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Stephanie W.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Sierra Stevens",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Thomas H.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Jennifer Barber",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Christopher C.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Sharon Nguyen",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Mariah M.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Robert Medina",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Lynn D.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Alex Jones",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Brooke B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-30",
        "review_rate": 5.0,
        "customer_name": "Kathleen Spencer",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Stacey M.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-16",
        "review_rate": 5.0,
        "customer_name": "Julie Jacobs",
        "service_type": "Garage Door Insulation",
        "tech_name": "Joshua B.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Frank Terry",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Darryl S.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Dr. Alicia Taylor DVM",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Anthony M.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.8,
        "customer_name": "Jennifer Sharp",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Scott H.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Kevin Huerta",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brianna R.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-18",
        "review_rate": 5.0,
        "customer_name": "Julie Bell",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Marc R.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Joshua Guzman",
        "service_type": "Garage Door Repair",
        "tech_name": "Katherine W.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Amanda Williams",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Julie G.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Amber Robinson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Robert M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.8,
        "customer_name": "Jason Orr",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Dylan R.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-25",
        "review_rate": 5.0,
        "customer_name": "Travis Bailey",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Cody S.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.8,
        "customer_name": "Cameron Barton",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Karen C.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Paula Moore",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Tracy S.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-27",
        "review_rate": 5.0,
        "customer_name": "Rachel Costa",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Linda B.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-11",
        "review_rate": 5.0,
        "customer_name": "Laura Nelson",
        "service_type": "Garage Door Installation",
        "tech_name": "Joseph D.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-06",
        "review_rate": 5.0,
        "customer_name": "Alicia Stanton DVM",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Ryan W.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-21",
        "review_rate": 5.0,
        "customer_name": "Lisa Contreras",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Paul C.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Shaun Potter",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Michael T.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.8,
        "customer_name": "Thomas Carpenter",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Donna R.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Jennifer Tran",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "John H.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-29",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Callahan",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Phillip L.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Alex Smith",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Michelle W.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.8,
        "customer_name": "Jeffery Aguilar",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Tristan M.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "Dennis Woodward",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Alexander H.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Kenneth Martin",
        "service_type": "Garage Door Off Track",
        "tech_name": "Steven B.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Julia Smith",
        "service_type": "Garage Door Off Track",
        "tech_name": "Crystal R.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.8,
        "customer_name": "Jesus Larson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Patrick E.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.8,
        "customer_name": "Henry Cruz",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Clarence M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Brittany Randall",
        "service_type": "Garage Door Insulation",
        "tech_name": "Stacie M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-30",
        "review_rate": 5.0,
        "customer_name": "Kristy Bernard",
        "service_type": "Garage Door Opener",
        "tech_name": "Dawn H.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-09",
        "review_rate": 5.0,
        "customer_name": "Anthony Tate",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Thomas W.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Joseph Kirk DVM",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Christopher L.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Miguel Bennett",
        "service_type": "Garage Door Installation",
        "tech_name": "Mitchell T.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.8,
        "customer_name": "Cynthia Kemp",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Alyssa P.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Jonathan Price",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Derek B.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Kendra Wright",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Joseph W.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.8,
        "customer_name": "Julie Davis",
        "service_type": "Garage Door Off Track",
        "tech_name": "Stacy J.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Lori Ramos",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michael W.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.8,
        "customer_name": "Carrie Walker",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Charles M.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.8,
        "customer_name": "Kayla Ware",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Elizabeth G.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Breanna Gonzales",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Sarah M.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Meghan Jones",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Nicholas P.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Jeremy Rosales",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Katherine D.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-29",
        "review_rate": 5.0,
        "customer_name": "Michelle Vaughan",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Brandy M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Lindsay Lee",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Melissa P.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Katherine Brewer",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Monica E.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Terry Peters",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Sheila H.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Walls",
        "service_type": "Garage Door Services",
        "tech_name": "Rachael B.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Ricardo Wilson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Joshua C.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.8,
        "customer_name": "Brandon Rogers",
        "service_type": "Garage Door Off Track",
        "tech_name": "Caitlin L.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Jessica Ramirez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Eric S.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-31",
        "review_rate": 5.0,
        "customer_name": "Gerald Cruz",
        "service_type": "Garage Door Installation",
        "tech_name": "Jennifer G.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.8,
        "customer_name": "James Wilson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Rhonda N.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-04",
        "review_rate": 5.0,
        "customer_name": "James Rodgers MD",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "James T.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "Frederick Rivera",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Dawn R.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.8,
        "customer_name": "Debra Davies",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Lori C.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-09",
        "review_rate": 5.0,
        "customer_name": "Gloria Love",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Elizabeth R.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Jonathan Parker",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Shannon M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "David Peters",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michelle H.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Charles Randall",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Allison B.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Mark Black",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Emma H.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "Debra Green",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jason O.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.8,
        "customer_name": "Fred Williams",
        "service_type": "Garage Door Repair",
        "tech_name": "Anthony C.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-13",
        "review_rate": 5.0,
        "customer_name": "Darius Roberts",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Todd L.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Aaron Best",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Amber M.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Tami Rodriguez",
        "service_type": "Garage Door Services",
        "tech_name": "Valerie R.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Danny Reed",
        "service_type": "Garage Door Services",
        "tech_name": "Gregory R.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Preston Nicholson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kathleen B.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-05",
        "review_rate": 5.0,
        "customer_name": "Lisa Martinez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Heidi L.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Eric Garcia",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Michael A.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Tiffany Ford",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Olivia S.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-16",
        "review_rate": 5.0,
        "customer_name": "Tamara Wheeler",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Rachel H.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Brett Tucker",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Michael D.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-01",
        "review_rate": 5.0,
        "customer_name": "Jasmin Reyes",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Sean B.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-26",
        "review_rate": 5.0,
        "customer_name": "Paul Walker",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Christian R.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-17",
        "review_rate": 5.0,
        "customer_name": "Courtney Stewart",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Michael H.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Jason Kramer",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Derek C.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-08",
        "review_rate": 5.0,
        "customer_name": "Christina Dominguez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Duane L.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.8,
        "customer_name": "Jose Moore",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Erica W.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.8,
        "customer_name": "Amber Nelson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Andrew M.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Regina Jackson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Heidi M.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-13",
        "review_rate": 5.0,
        "customer_name": "Melissa Coleman",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Mariah W.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-10",
        "review_rate": 5.0,
        "customer_name": "Tonya Fuller",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Marie B.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Carlos Taylor",
        "service_type": "Garage Door Services",
        "tech_name": "Monica J.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.8,
        "customer_name": "Mrs. Barbara Bell MD",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Lisa B.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.8,
        "customer_name": "Laura Dawson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Carl E.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.8,
        "customer_name": "James Cummings",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Richard C.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-10",
        "review_rate": 5.0,
        "customer_name": "Steven Walters",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sarah L.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Mrs. Julie Diaz",
        "service_type": "Garage Door Opener",
        "tech_name": "Brianna T.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-27",
        "review_rate": 5.0,
        "customer_name": "Sean Brown MD",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jennifer R.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Christopher Harris",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "John P.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-05",
        "review_rate": 5.0,
        "customer_name": "James Haley",
        "service_type": "Garage Door Installation",
        "tech_name": "Robin H.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.8,
        "customer_name": "Meghan Mcguire",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Miranda P.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Rebecca Bell",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Nicole R.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Gary Miller",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Lance R.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-09",
        "review_rate": 5.0,
        "customer_name": "Kimberly Floyd",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Courtney A.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-01",
        "review_rate": 5.0,
        "customer_name": "Christina Fry",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Katie M.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Lawson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Susan F.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Brian Durham",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "David S.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "James Baker",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Eric N.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.8,
        "customer_name": "Samuel Stone",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kelly S.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.8,
        "customer_name": "Hunter Murray",
        "service_type": "Garage Door Track Repair",
        "tech_name": "James M.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Johnson MD",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Nathan G.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.8,
        "customer_name": "Gina Sutton",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Christopher S.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-23",
        "review_rate": 5.0,
        "customer_name": "Natasha Jordan",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Craig C.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.8,
        "customer_name": "Wesley Lee",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Cameron R.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.8,
        "customer_name": "Mark Maynard",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Mark M.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Karen Smith",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jamie K.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Dr. Carolyn Harrison",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Brittany L.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Makayla Hampton",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Craig M.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Michael Johnson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Diane F.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.8,
        "customer_name": "William Moore",
        "service_type": "Garage Door Installation",
        "tech_name": "Matthew P.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-19",
        "review_rate": 5.0,
        "customer_name": "Joshua Villanueva",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Daniel V.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Marie Reed",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kenneth J.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.9,
        "customer_name": "Brenda Martinez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kimberly W.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.8,
        "customer_name": "Ann Gonzalez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michael W.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.8,
        "customer_name": "Sandra Ferguson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kevin T.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Brandi Burnett",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "James R.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-24",
        "review_rate": 5.0,
        "customer_name": "Sarah Nelson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Rachel D.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Kathryn Johnson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Brenda R.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Jessica Schmidt",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Lisa T.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.8,
        "customer_name": "Megan Tucker",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Donna B.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-06",
        "review_rate": 5.0,
        "customer_name": "William Hess",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Paige P.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Mrs. Sara Rojas",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Neil G.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-26",
        "review_rate": 5.0,
        "customer_name": "Alicia Romero",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Mark G.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.8,
        "customer_name": "Kimberly Walter",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Matthew L.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-28",
        "review_rate": 5.0,
        "customer_name": "Kelly Montoya",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Debra W.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.8,
        "customer_name": "Ashley Smith",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "David G.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Michelle Thomas",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Tiffany B.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-25",
        "review_rate": 5.0,
        "customer_name": "Kimberly Roberts",
        "service_type": "Garage Door Repair",
        "tech_name": "Jesus H.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-25",
        "review_rate": 5.0,
        "customer_name": "William Howard",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Candice R.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Jason Johnson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jessica S.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Joshua Lewis",
        "service_type": "Garage Door Repair",
        "tech_name": "Richard M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Timothy Bender",
        "service_type": "Garage Door Repair",
        "tech_name": "Brian L.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.8,
        "customer_name": "Jamie Elliott",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Judy D.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Christopher Schmidt",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jimmy A.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Shawna Johnson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Dean S.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-22",
        "review_rate": 5.0,
        "customer_name": "Diana Warner",
        "service_type": "Garage Door Repair",
        "tech_name": "Terri M.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Anthony Vasquez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Melissa G.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Jasmine Mcmillan",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jason D.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "John Pace",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Lisa M.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Amanda Taylor",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Rhonda H.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Phillip Sanford",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Carmen W.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-13",
        "review_rate": 5.0,
        "customer_name": "Jennifer Whitaker",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Christina A.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Monica House",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Linda P.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Christopher Hale",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Stacey F.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.8,
        "customer_name": "Joshua Campbell",
        "service_type": "Garage Door Maintenance",
        "tech_name": "William A.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Christine Anderson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Briana G.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "Roger Stevens",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Matthew S.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-20",
        "review_rate": 5.0,
        "customer_name": "Robert Liu",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Joshua K.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.8,
        "customer_name": "Vanessa Torres",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Cody L.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Ashley Knight",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Eric H.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-16",
        "review_rate": 5.0,
        "customer_name": "Heidi King",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "James F.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Sarah Johnson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Thomas H.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "William Hicks",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Travis C.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.8,
        "customer_name": "Walter Marshall",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Nicholas F.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Jose Evans",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Crystal S.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-24",
        "review_rate": 5.0,
        "customer_name": "Christopher Vega",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Robert W.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Jeremy Molina",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Laura M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.8,
        "customer_name": "Brian Foster",
        "service_type": "Garage Door Installation",
        "tech_name": "Michael W.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-07",
        "review_rate": 5.0,
        "customer_name": "Clayton Johnson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Eric N.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-09-30",
        "review_rate": 5.0,
        "customer_name": "Amanda Mack",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jennifer R.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Carla Rodriguez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Stephen C.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Joshua Bean",
        "service_type": "Garage Door Opener",
        "tech_name": "James O.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.8,
        "customer_name": "Wendy Carpenter",
        "service_type": "Garage Door Off Track",
        "tech_name": "Mark M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.8,
        "customer_name": "Daniel Reed",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kendra J.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.8,
        "customer_name": "Angela Lyons",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Stephanie B.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Vicki Davis",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Cassandra H.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-19",
        "review_rate": 5.0,
        "customer_name": "Jason Underwood",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Holly W.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "James Andrade",
        "service_type": "Garage Door Opener",
        "tech_name": "Stephanie S.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Charles Savage",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Maureen S.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Sharon Pierce",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Grace W.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.9,
        "customer_name": "Paul White",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Steven G.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.8,
        "customer_name": "Allison Kelly",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Erik B.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Joshua Hubbard",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Amy W.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.8,
        "customer_name": "Victoria Garrett",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Leslie N.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.8,
        "customer_name": "Steven Moran",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Brianna S.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-24",
        "review_rate": 5.0,
        "customer_name": "Robert Alvarez",
        "service_type": "Garage Door Repair",
        "tech_name": "Michelle M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Charles Mckay",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Samuel W.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Erica Hernandez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michelle H.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.8,
        "customer_name": "Traci Dyer",
        "service_type": "Garage Door Opener",
        "tech_name": "Melissa S.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-12",
        "review_rate": 5.0,
        "customer_name": "Megan Castillo",
        "service_type": "Garage Door Services",
        "tech_name": "Laura R.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-27",
        "review_rate": 5.0,
        "customer_name": "Rodney Washington",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Heather B.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Janet Bell",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Joseph H.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Jamie Wagner",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Steven H.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Timothy Adams",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kathy H.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.8,
        "customer_name": "Scott Miller",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Katherine M.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Sue Raymond",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Nicole H.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Russell Spencer",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Troy R.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-16",
        "review_rate": 5.0,
        "customer_name": "Michael Butler",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Robert C.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-29",
        "review_rate": 5.0,
        "customer_name": "Leslie Payne",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Pamela H.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Sharon Rodriguez",
        "service_type": "Garage Door Repair",
        "tech_name": "Brittany J.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-03",
        "review_rate": 5.0,
        "customer_name": "Christine Orozco",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Patricia M.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Mary Barry",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jorge J.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Cindy Lucero",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Zachary L.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.8,
        "customer_name": "Daniel Williams",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Timothy J.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Nancy Wood",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Stephanie S.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-07",
        "review_rate": 5.0,
        "customer_name": "Ebony Rhodes",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Nicholas R.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.8,
        "customer_name": "Linda Meza",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Sierra S.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Samantha Galvan",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kimberly B.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Carlos Stevens",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Martin M.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-22",
        "review_rate": 5.0,
        "customer_name": "Amanda Brooks",
        "service_type": "Garage Door Repair",
        "tech_name": "Justin H.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Jose Bernard",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Laura E.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-17",
        "review_rate": 5.0,
        "customer_name": "Bailey Medina",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Katherine B.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-23",
        "review_rate": 5.0,
        "customer_name": "Richard Cardenas",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Steve S.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.8,
        "customer_name": "Daniel Lam",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Sydney J.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.8,
        "customer_name": "Rodney Lawson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jessica R.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-06",
        "review_rate": 5.0,
        "customer_name": "Samantha Schneider",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Sheri R.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Michael Lamb",
        "service_type": "Garage Door Services",
        "tech_name": "Karen F.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.8,
        "customer_name": "Joshua Taylor",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Gabrielle M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Lawrence Washington",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Robert G.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-03",
        "review_rate": 5.0,
        "customer_name": "Priscilla Jacobs",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "George R.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-19",
        "review_rate": 5.0,
        "customer_name": "Cynthia Powell",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Chad G.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-18",
        "review_rate": 5.0,
        "customer_name": "Kelli Parker",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Brittany W.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-15",
        "review_rate": 5.0,
        "customer_name": "Stephen Weaver",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Frank R.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.8,
        "customer_name": "Kristin Hall",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kimberly C.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Michele James",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jeffrey G.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Daniel Moore",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "William N.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Amy Irwin",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kaitlyn L.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.8,
        "customer_name": "Caleb Wilson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Bruce P.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-15",
        "review_rate": 5.0,
        "customer_name": "Jacob Walker",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Victoria O.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Amanda Alvarado",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "David P.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-22",
        "review_rate": 5.0,
        "customer_name": "Eric Perkins",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Thomas F.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "Larry Robinson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Patricia P.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Michelle Lindsey",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Tammy B.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-10",
        "review_rate": 5.0,
        "customer_name": "Savannah Sims",
        "service_type": "Garage Door Installation",
        "tech_name": "Karina S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Mr. Joel Henderson",
        "service_type": "Garage Door Installation",
        "tech_name": "Kayla K.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.8,
        "customer_name": "Hannah Burns",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jessica H.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Omar Moore",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Stephanie M.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-09",
        "review_rate": 5.0,
        "customer_name": "Daniel Rivers",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Mary S.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-01",
        "review_rate": 5.0,
        "customer_name": "Jessica Brown",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Sara N.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-28",
        "review_rate": 5.0,
        "customer_name": "Heidi Henderson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Brian H.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Lucas Gates",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Tanner M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-15",
        "review_rate": 5.0,
        "customer_name": "Richard Jefferson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Brandon P.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Loretta Jackson DVM",
        "service_type": "Garage Door Opener",
        "tech_name": "Kimberly F.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Ashley Evans",
        "service_type": "Garage Door Opener",
        "tech_name": "Angela A.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-12",
        "review_rate": 5.0,
        "customer_name": "Catherine Knox",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Chad W.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.8,
        "customer_name": "Megan Jones",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Barry K.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Sandra Holland",
        "service_type": "Garage Door Repair",
        "tech_name": "Whitney P.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.8,
        "customer_name": "Wendy Mclean",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Angela S.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Sanders",
        "service_type": "Garage Door Services",
        "tech_name": "Rachel T.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.8,
        "customer_name": "Sabrina Cardenas",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Andrew A.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.8,
        "customer_name": "Kylie Benjamin",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Nicole P.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.8,
        "customer_name": "Megan Parker",
        "service_type": "Garage Door Repair",
        "tech_name": "Cynthia S.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.8,
        "customer_name": "Pamela Horn",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Teresa W.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-20",
        "review_rate": 5.0,
        "customer_name": "April Kemp",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kevin T.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.8,
        "customer_name": "Damon Mason",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Susan D.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-09-27",
        "review_rate": 5.0,
        "customer_name": "James Thornton",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "John O.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Travis Patterson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Samantha A.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.8,
        "customer_name": "Catherine Anderson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Mary G.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Lonnie Joseph",
        "service_type": "Garage Door Services",
        "tech_name": "Shawna V.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "Juan Hughes",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Heather A.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.8,
        "customer_name": "David Wilson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Brendan B.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.8,
        "customer_name": "Tiffany Franklin",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Dawn L.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Stacey Lowe",
        "service_type": "Garage Door Repair",
        "tech_name": "Monica E.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-31",
        "review_rate": 5.0,
        "customer_name": "Kayla Barker",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jennifer L.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Brian Nguyen",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Robert S.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Melissa Cooke",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Anthony S.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Robert Lang",
        "service_type": "Garage Door Repair",
        "tech_name": "Lisa G.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "William Lopez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Juan M.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.8,
        "customer_name": "Jessica Smith",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Tonya A.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Mrs. Jennifer Hill",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kelly W.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.8,
        "customer_name": "David Franco",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jared M.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Shannon Anthony",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jessica M.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.8,
        "customer_name": "Jason Rodriguez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Richard J.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.8,
        "customer_name": "James Robles",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Stacey C.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-12",
        "review_rate": 5.0,
        "customer_name": "Kathy Lee",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Laura G.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Jonathan Kaufman",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Steven W.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Gina White",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Rhonda O.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-05",
        "review_rate": 5.0,
        "customer_name": "Noah Hartman",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Nicole P.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Carla Hill",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Suzanne W.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-17",
        "review_rate": 5.0,
        "customer_name": "John Olson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kimberly R.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.8,
        "customer_name": "Jacob Thomas",
        "service_type": "Garage Door Repair",
        "tech_name": "Sharon M.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "Richard Lin",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Rachel C.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Jonathan Jackson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Katherine H.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Ashley Sharp",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Shannon F.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Kyle Bryan",
        "service_type": "Garage Door Installation",
        "tech_name": "Ashley K.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Richard Perez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Melissa R.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.8,
        "customer_name": "Micheal Walker",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Ellen M.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Carlos Nixon",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Amber T.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.8,
        "customer_name": "Darryl Macdonald",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Maurice B.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.8,
        "customer_name": "Carolyn Jackson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "David K.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-31",
        "review_rate": 5.0,
        "customer_name": "Alexander Hogan",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Rachael D.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Leslie Huber",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Sarah B.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.8,
        "customer_name": "Daniel Daugherty",
        "service_type": "Garage Door Installation",
        "tech_name": "Sarah V.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-21",
        "review_rate": 5.0,
        "customer_name": "Deborah Coleman",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Carlos P.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-01",
        "review_rate": 5.0,
        "customer_name": "Gary Goodwin",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Julie C.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-25",
        "review_rate": 5.0,
        "customer_name": "Jasmine Gallagher",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jessica F.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Sean Campos",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Richard P.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.8,
        "customer_name": "Joann Cannon",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kyle C.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "Amanda Mcmahon",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Travis A.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Samantha Maddox",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Steven M.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Christopher Wilson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "James W.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Robert Mcdowell",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Robert S.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-29",
        "review_rate": 5.0,
        "customer_name": "Gwendolyn Cooley",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Michael R.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-14",
        "review_rate": 5.0,
        "customer_name": "Shawn Figueroa",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Wendy W.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.8,
        "customer_name": "Gary Espinoza",
        "service_type": "Garage Door Installation",
        "tech_name": "Mary B.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-06",
        "review_rate": 5.0,
        "customer_name": "Leonard Miller",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Aaron S.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "David Jones",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Carolyn B.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.8,
        "customer_name": "Jodi Poole",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jill J.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.8,
        "customer_name": "Melissa Perkins",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Melanie J.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.8,
        "customer_name": "Russell Taylor",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Ana M.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-06",
        "review_rate": 5.0,
        "customer_name": "Tonya Fletcher",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jason C.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Rebecca Leon",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Sarah C.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-10",
        "review_rate": 5.0,
        "customer_name": "David Gray",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Crystal M.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Troy Dixon",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Rachel G.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.8,
        "customer_name": "Anthony Lane",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kelly M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.8,
        "customer_name": "Ryan Morris",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Shelly J.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.8,
        "customer_name": "Terry Greene",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Nicole R.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Meredith Thompson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Ashley S.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.8,
        "customer_name": "Jacqueline Vaughn",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michael J.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Amanda Howe",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Olivia W.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.8,
        "customer_name": "Robert Johnson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "James H.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Mrs. Alyssa Brock",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Mark G.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "Martin Henson",
        "service_type": "Garage Door Opener",
        "tech_name": "Robert H.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.8,
        "customer_name": "Karen Little",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Victor R.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Daniel Smith",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Teresa M.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Robert Mullins",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Andrew C.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-11",
        "review_rate": 5.0,
        "customer_name": "Sara Clark",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Danielle M.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-19",
        "review_rate": 5.0,
        "customer_name": "Rachel Ortiz",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Brian G.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-17",
        "review_rate": 5.0,
        "customer_name": "Michael Byrd",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Megan M.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-25",
        "review_rate": 5.0,
        "customer_name": "Cynthia May",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Toni H.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Michael Moore",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Dustin D.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Brenda Moore",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Preston L.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.8,
        "customer_name": "Mr. Joseph Acevedo",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Stacie H.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Stephanie Miller DDS",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "George K.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.8,
        "customer_name": "Lisa Johnston",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Paul M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-30",
        "review_rate": 5.0,
        "customer_name": "Gregory Ramirez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Natalie W.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Rose Jones",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jason M.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Jordan Smith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Stacy C.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.8,
        "customer_name": "Larry Campos",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Justin R.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-08",
        "review_rate": 5.0,
        "customer_name": "Joshua Allison",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Bianca B.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Alexis Reyes",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Joseph W.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Mary Sutton",
        "service_type": "Garage Door Opener",
        "tech_name": "James F.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Matthew Burnett",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Colleen A.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Kenneth Tran",
        "service_type": "Garage Door Installation",
        "tech_name": "Catherine D.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-25",
        "review_rate": 5.0,
        "customer_name": "Donna Clark",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Aaron B.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-22",
        "review_rate": 5.0,
        "customer_name": "John Walker",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Tracey B.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-08",
        "review_rate": 5.0,
        "customer_name": "Lynn Wright",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jamie B.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-16",
        "review_rate": 5.0,
        "customer_name": "Carolyn Stokes",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Mary M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Catherine Rubio",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jose C.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Stephanie Torres",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Grant D.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-20",
        "review_rate": 5.0,
        "customer_name": "Bryan Barrera",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jason J.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.8,
        "customer_name": "Scott Lawrence",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michael F.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-21",
        "review_rate": 5.0,
        "customer_name": "Rachel Alvarez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Crystal R.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.8,
        "customer_name": "Stacy Wilson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Linda S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Patrick Griffin",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kyle C.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Mackenzie Mitchell",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sarah C.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.8,
        "customer_name": "Jacob Jennings",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Antonio G.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.8,
        "customer_name": "Jenny Chase",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Keith L.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.8,
        "customer_name": "James Cox",
        "service_type": "Garage Door Opener",
        "tech_name": "Dennis W.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Alison Smith",
        "service_type": "Garage Door Insulation",
        "tech_name": "April J.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Alec Pearson III",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Blake C.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Natasha Wagner",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Angela N.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Lauren Watson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Alexander B.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Lisa Nelson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Andrea V.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-07",
        "review_rate": 5.0,
        "customer_name": "Jessica Davis",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kimberly M.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Philip Pena",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Robert B.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.8,
        "customer_name": "Ricky Moody",
        "service_type": "Garage Door Insulation",
        "tech_name": "Andre S.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Kevin Oneill",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jason B.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Nathaniel Mccarthy",
        "service_type": "Garage Door Opener",
        "tech_name": "Anthony M.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.8,
        "customer_name": "Michelle Rojas",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Gregory K.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-19",
        "review_rate": 5.0,
        "customer_name": "Jennifer Sherman",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Amanda W.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.8,
        "customer_name": "Richard Jones",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jesus H.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-28",
        "review_rate": 5.0,
        "customer_name": "Jennifer Reyes",
        "service_type": "Garage Door Services",
        "tech_name": "Eric B.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.8,
        "customer_name": "Richard Walters PhD",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Valerie J.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Charles Johnson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Christina B.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-16",
        "review_rate": 5.0,
        "customer_name": "Rebecca Schwartz",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Alexander R.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Kyle Turner",
        "service_type": "Garage Door Services",
        "tech_name": "William K.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.8,
        "customer_name": "Alexandria Brennan",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Leonard R.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Desiree Scott",
        "service_type": "Garage Door Repair",
        "tech_name": "Julie B.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Jamie Miles",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Clinton B.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-16",
        "review_rate": 5.0,
        "customer_name": "Savannah Crawford",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "James W.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Peter Jackson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Robert B.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Kelsey Phillips",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Brandon L.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Nicole James",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Lisa C.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Robin Ramsey",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Zachary B.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-06",
        "review_rate": 5.0,
        "customer_name": "Brian Brown",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kylie C.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Justin Savage",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kevin H.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Timothy Andrews",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Brandon C.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.8,
        "customer_name": "Teresa Jones",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Sylvia A.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Jennifer Bowman",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jesus H.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Penny Sullivan",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Joseph V.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.8,
        "customer_name": "Suzanne Le MD",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jose M.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.8,
        "customer_name": "Andrew Jackson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Derek P.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Aaron Russell",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christopher J.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-22",
        "review_rate": 5.0,
        "customer_name": "Jennifer Ward",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Amy P.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Caitlin Garcia",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Nicole T.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Jonathan Garrison",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Edward K.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.8,
        "customer_name": "David Raymond",
        "service_type": "Garage Door Insulation",
        "tech_name": "Billy R.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-19",
        "review_rate": 5.0,
        "customer_name": "Emily King",
        "service_type": "Garage Door Repair",
        "tech_name": "Amber F.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.8,
        "customer_name": "Christina Clark",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Karen P.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Lauren Hill",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Janet D.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Edwin Campbell",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Ruth R.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.8,
        "customer_name": "Patricia Flores",
        "service_type": "Garage Door Opener",
        "tech_name": "Stephanie P.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-04",
        "review_rate": 5.0,
        "customer_name": "Claudia Martin",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Daisy H.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.8,
        "customer_name": "Susan Cain",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Lisa M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.8,
        "customer_name": "Alex Lopez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "John F.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.8,
        "customer_name": "Jonathan Lee",
        "service_type": "Garage Door Installation",
        "tech_name": "Stephen M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Lauren Marshall",
        "service_type": "Garage Door Off Track",
        "tech_name": "Brandon C.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.8,
        "customer_name": "Nathan Banks",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Christopher P.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.8,
        "customer_name": "David Barnett",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Cynthia D.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Bradley Velasquez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Patrick S.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Michael Navarro",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Brian C.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Janet Klein",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "April M.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.8,
        "customer_name": "Andrea Ramirez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Amanda P.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "William Andrews",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Nicole L.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Erin Carlson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Sherry D.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Renee Gallegos",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christina L.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Briana Bell",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Tyler J.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Brett Norton",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Andrea M.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Daniel Diaz",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christina R.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Emily Horton",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kevin G.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Meagan Tucker",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "John D.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-01",
        "review_rate": 5.0,
        "customer_name": "Donald Lewis",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jason N.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.8,
        "customer_name": "Amy Munoz",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Melissa G.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-29",
        "review_rate": 5.0,
        "customer_name": "Erik Soto",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Scott W.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Stephen Walker",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Marie M.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.8,
        "customer_name": "Lisa Bradley",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jessica C.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-13",
        "review_rate": 5.0,
        "customer_name": "Christina Alexander",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Tanya S.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.8,
        "customer_name": "Travis Jackson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Vincent A.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Jeremy Nolan",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jennifer M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.8,
        "customer_name": "David Robles",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Marilyn T.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-22",
        "review_rate": 5.0,
        "customer_name": "Jared Anderson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Felicia A.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Catherine Travis",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Matthew J.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Tina Moore",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Christine B.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Marcia Compton",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kirsten D.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Alison Walsh",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Wendy G.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Brandon Martin",
        "service_type": "Garage Door Installation",
        "tech_name": "David M.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Sara Elliott",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jody H.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Tony Macdonald",
        "service_type": "Custom Garage Door Design",
        "tech_name": "John W.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Stephanie Hammond",
        "service_type": "Garage Door Off Track",
        "tech_name": "Adriana W.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-04",
        "review_rate": 5.0,
        "customer_name": "Yvonne Anderson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Samantha S.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Scott Clark",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "David V.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Donald Hoover",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Brian J.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Paul Duke",
        "service_type": "Garage Door Installation",
        "tech_name": "Erika C.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Michael Campbell",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Judy C.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.8,
        "customer_name": "David Casey",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sierra G.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Misty Buck",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Roger T.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-29",
        "review_rate": 5.0,
        "customer_name": "Margaret Duarte",
        "service_type": "Garage Door Installation",
        "tech_name": "Jennifer B.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Andrew Hernandez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jessica R.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.8,
        "customer_name": "John Becker",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Christopher G.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Susan Collins",
        "service_type": "Garage Door Repair",
        "tech_name": "Brett I.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.8,
        "customer_name": "Austin Wilson",
        "service_type": "Garage Door Opener",
        "tech_name": "Christopher S.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-18",
        "review_rate": 5.0,
        "customer_name": "Steven Osborne",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Eric W.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-24",
        "review_rate": 5.0,
        "customer_name": "Brett Nichols",
        "service_type": "Custom Garage Door Design",
        "tech_name": "James P.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Stacie Webb",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Zachary W.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Michael Norman",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Hannah S.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "James Brown",
        "service_type": "Garage Door Installation",
        "tech_name": "Robert J.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-12",
        "review_rate": 5.0,
        "customer_name": "Danielle Peterson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Gilbert G.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Rachel Brennan",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michelle G.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.8,
        "customer_name": "Joshua Khan DVM",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Cory S.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.8,
        "customer_name": "Dylan Jenkins",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Alexandria D.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Barbara Lee",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Yvonne M.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Sandra Lang",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jessica G.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-16",
        "review_rate": 5.0,
        "customer_name": "Kenneth Warner",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Brandon P.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Shane Lynch",
        "service_type": "Garage Door Repair",
        "tech_name": "Amy C.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.8,
        "customer_name": "Jillian Munoz",
        "service_type": "Garage Door Repair",
        "tech_name": "Garrett P.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Sandra Clark MD",
        "service_type": "Garage Door Opener",
        "tech_name": "Jaime H.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-30",
        "review_rate": 5.0,
        "customer_name": "Kyle Hill",
        "service_type": "Garage Door Opener",
        "tech_name": "Stephen F.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Stacey Reid",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Andrew J.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Ryan Robles",
        "service_type": "Garage Door Opener",
        "tech_name": "Zachary H.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.8,
        "customer_name": "Kathryn Mcdaniel",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Heidi C.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.8,
        "customer_name": "Kaitlyn Kelly",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Thomas L.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "James Compton",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Aaron B.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Jessica Villarreal",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Joseph A.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.8,
        "customer_name": "Scott Lucero",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kelly P.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Loretta Holmes",
        "service_type": "Garage Door Repair",
        "tech_name": "Heather B.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-07",
        "review_rate": 5.0,
        "customer_name": "Michael Howard",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Nicholas B.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "Darlene Figueroa",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Anthony D.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.8,
        "customer_name": "John Bernard",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Shane M.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.8,
        "customer_name": "Jodi Long",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Nicole H.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-09",
        "review_rate": 5.0,
        "customer_name": "Kathy Johnson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jacob K.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-02",
        "review_rate": 5.0,
        "customer_name": "Barbara Robinson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Paul W.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Jeffery Velez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Ashley L.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Sharon Andrade",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Erica O.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-17",
        "review_rate": 5.0,
        "customer_name": "Danielle Lee",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Ronald V.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-01",
        "review_rate": 5.0,
        "customer_name": "Eric Holloway",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Joseph L.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.8,
        "customer_name": "Kyle David",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Benjamin C.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-12",
        "review_rate": 5.0,
        "customer_name": "Mark Wright",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Vanessa K.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "William Zuniga",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Sherri W.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Bryan Jimenez",
        "service_type": "Garage Door Repair",
        "tech_name": "Angela M.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Stephanie Moreno",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Emily M.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.8,
        "customer_name": "Wanda Velez",
        "service_type": "Garage Door Opener",
        "tech_name": "Brian H.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-27",
        "review_rate": 5.0,
        "customer_name": "Joseph Flores",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Robert J.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Ryan Long",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Karen O.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Nancy Barton",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brenda W.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.9,
        "customer_name": "Andres Moore",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Melissa C.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.8,
        "customer_name": "Kevin Norris",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Isabel H.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-30",
        "review_rate": 5.0,
        "customer_name": "Morgan Rosales",
        "service_type": "Garage Door Off Track",
        "tech_name": "Thomas L.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.8,
        "customer_name": "Miss Kristen Soto",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Philip J.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Kevin Vargas",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jonathan O.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Nicole Winters",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Thomas C.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-10",
        "review_rate": 5.0,
        "customer_name": "Erika Clark",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Ryan N.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Heidi Gray",
        "service_type": "Garage Door Installation",
        "tech_name": "Karen H.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-21",
        "review_rate": 5.0,
        "customer_name": "Kaitlyn Wade",
        "service_type": "Garage Door Opener",
        "tech_name": "Melissa L.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Carly Delacruz",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Glenda Y.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.8,
        "customer_name": "Jessica Taylor",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "James H.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-12",
        "review_rate": 5.0,
        "customer_name": "Chelsea Sanchez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Christina B.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Diana Owens",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Amy R.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Amanda Poole",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Amber D.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.8,
        "customer_name": "David Figueroa",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Patrick D.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-13",
        "review_rate": 5.0,
        "customer_name": "Aaron Pruitt",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Keith M.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Alyssa Friedman",
        "service_type": "Garage Door Installation",
        "tech_name": "Ryan W.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.8,
        "customer_name": "Janet Vargas",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Terri S.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Gina Harvey",
        "service_type": "Garage Door Installation",
        "tech_name": "Matthew V.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-02",
        "review_rate": 5.0,
        "customer_name": "Melissa Mitchell",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Michelle H.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.8,
        "customer_name": "James Fitzgerald",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Timothy B.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Andrew Taylor",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Larry R.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Amber Robinson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Brian R.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-16",
        "review_rate": 5.0,
        "customer_name": "Dawn Manning",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jonathan H.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-02",
        "review_rate": 5.0,
        "customer_name": "Douglas Brooks",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Karen B.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-24",
        "review_rate": 5.0,
        "customer_name": "Adrian Haynes",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Rachel S.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.8,
        "customer_name": "Janet Wilcox",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Tammy J.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-12",
        "review_rate": 5.0,
        "customer_name": "Scott Cohen",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "April M.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.8,
        "customer_name": "Chelsey Gilbert",
        "service_type": "Garage Door Off Track",
        "tech_name": "Carl W.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Heather Carr MD",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kimberly B.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Ronald Cook",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Olivia D.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-16",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Phillips",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Billy L.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Aaron Conley",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Christian C.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.8,
        "customer_name": "Holly Wolf",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Anthony J.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.9,
        "customer_name": "Xavier Alexander",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Danielle K.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Anna Tran",
        "service_type": "Garage Door Services",
        "tech_name": "David C.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Robert Frye",
        "service_type": "Garage Door Services",
        "tech_name": "Debra R.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.8,
        "customer_name": "Luke Carroll",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jennifer G.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-13",
        "review_rate": 5.0,
        "customer_name": "Bobby Jones",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Matthew P.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-30",
        "review_rate": 5.0,
        "customer_name": "Juan Young",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Alison T.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.8,
        "customer_name": "Carlos Patterson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Willie H.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Anne Pollard",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Sherry W.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Laura Daniel",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Vincent M.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.8,
        "customer_name": "Renee Logan",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Crystal F.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-24",
        "review_rate": 5.0,
        "customer_name": "Kathryn Reid",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Michael M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.8,
        "customer_name": "Laurie Schneider",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Paul W.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.8,
        "customer_name": "Laura Williams",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Katrina L.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Erin Jenkins",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jasmine D.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.8,
        "customer_name": "Mary Rogers",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Diane T.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-20",
        "review_rate": 5.0,
        "customer_name": "Robert Marsh",
        "service_type": "Garage Door Insulation",
        "tech_name": "Amanda O.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Nicole Adams",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Maria T.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-15",
        "review_rate": 5.0,
        "customer_name": "Craig Delgado",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Stanley J.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.9,
        "customer_name": "Tiffany Garcia",
        "service_type": "Residential Garage Door Services",
        "tech_name": "James I.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "David Green",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Catherine P.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Brandon Meadows",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kevin W.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-22",
        "review_rate": 5.0,
        "customer_name": "Pamela Caldwell",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Tamara C.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-11",
        "review_rate": 5.0,
        "customer_name": "Jodi Bradley",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kimberly H.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Brent Anderson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Anna B.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Evan Craig MD",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Ian M.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-26",
        "review_rate": 5.0,
        "customer_name": "Gabrielle Harrington",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Katelyn M.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Gregory Harrell",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Thomas D.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Cassandra Delacruz",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Shannon L.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.8,
        "customer_name": "David Brown",
        "service_type": "Garage Door Off Track",
        "tech_name": "Cory G.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-22",
        "review_rate": 5.0,
        "customer_name": "Nicole Smith",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Christopher M.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.8,
        "customer_name": "Michael Cox",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Connor K.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.8,
        "customer_name": "Brian Davis",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Ashlee H.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-28",
        "review_rate": 5.0,
        "customer_name": "Julie Thompson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Anthony K.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.8,
        "customer_name": "James Clay",
        "service_type": "Garage Door Repair",
        "tech_name": "Paul R.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Paul Bowen",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Mary L.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-31",
        "review_rate": 5.0,
        "customer_name": "Sarah Kirk",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Eric S.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Miguel Best",
        "service_type": "Garage Door Off Track",
        "tech_name": "Glenn R.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Lori Wilson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Maria W.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Lisa Wilson",
        "service_type": "Garage Door Repair",
        "tech_name": "Joshua L.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Amanda Collins",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Eddie K.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Christian Smith",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jamie J.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-07",
        "review_rate": 5.0,
        "customer_name": "David Carlson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Susan O.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Glenn Weiss",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Briana C.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Charles Holmes",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Desiree G.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.8,
        "customer_name": "John Smith",
        "service_type": "Garage Door Repair",
        "tech_name": "Denise W.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Henry Mcdonald",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Matthew A.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Jessica Johnson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Teresa B.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-09-29",
        "review_rate": 5.0,
        "customer_name": "Kathleen Freeman",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Gloria C.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-01",
        "review_rate": 5.0,
        "customer_name": "Matthew Perez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Howard R.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Bonnie Reyes",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Dakota W.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Michael Holt",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Ann J.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Carrie Watkins",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Deanna M.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-18",
        "review_rate": 5.0,
        "customer_name": "Edward Malone",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Angela M.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-31",
        "review_rate": 5.0,
        "customer_name": "Jessica Joseph",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jeremy F.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Jennifer Schaefer",
        "service_type": "Garage Door Repair",
        "tech_name": "Natasha S.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-12",
        "review_rate": 5.0,
        "customer_name": "Christina Garza",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jesse T.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Alexandra Nelson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jennifer J.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-11",
        "review_rate": 5.0,
        "customer_name": "Laura Smith",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Christina R.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-17",
        "review_rate": 5.0,
        "customer_name": "Rhonda Stewart",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Brenda S.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-29",
        "review_rate": 5.0,
        "customer_name": "Katherine Richards",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Mary M.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-18",
        "review_rate": 5.0,
        "customer_name": "Tyler Rosario",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Erin C.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.8,
        "customer_name": "Virginia Kelley",
        "service_type": "Garage Door Off Track",
        "tech_name": "Laurie L.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Christina Arnold",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Garrett B.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Kathleen Abbott",
        "service_type": "Garage Door Maintenance",
        "tech_name": "John L.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.8,
        "customer_name": "Mark Williams",
        "service_type": "Garage Door Insulation",
        "tech_name": "Bradley G.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-11",
        "review_rate": 5.0,
        "customer_name": "Jacob Turner",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jonathan P.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-30",
        "review_rate": 5.0,
        "customer_name": "Carol Hunter",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Amy M.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-24",
        "review_rate": 5.0,
        "customer_name": "Andre Burch",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Katherine D.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Brad Rojas",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Patricia B.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-02",
        "review_rate": 5.0,
        "customer_name": "Stephanie Ibarra",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Harry B.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "David Cortez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kathryn R.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-31",
        "review_rate": 5.0,
        "customer_name": "Travis Hernandez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Shawn W.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-07",
        "review_rate": 5.0,
        "customer_name": "Eric Lee",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Eric G.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-19",
        "review_rate": 5.0,
        "customer_name": "Tara Barry",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "David R.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Gary Henry",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Alyssa P.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Adams",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kimberly H.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Kathryn Chapman",
        "service_type": "Garage Door Installation",
        "tech_name": "Glenn D.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Darren Mckee",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kimberly P.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "William Mcdonald",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Gabrielle O.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.8,
        "customer_name": "Joshua Fleming",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Thomas G.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Jose Short",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Natasha S.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-09",
        "review_rate": 5.0,
        "customer_name": "Carrie Hubbard",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Madison B.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.8,
        "customer_name": "Ryan Patton",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Dennis P.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Mary Cruz",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Paul K.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "John Hanna",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Brittany F.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Dominic Aguilar",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Sarah F.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Jessica Lee",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Caroline P.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Courtney Singh",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Rebecca D.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.8,
        "customer_name": "Dylan Perez Jr.",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jacob E.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Robin Wilcox",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Aaron M.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-25",
        "review_rate": 5.0,
        "customer_name": "Patricia Holden",
        "service_type": "Garage Door Installation",
        "tech_name": "Taylor J.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Erin Roberson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Timothy B.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Becky Dunn",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jennifer G.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-04",
        "review_rate": 5.0,
        "customer_name": "Angela Morrison",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Anna H.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.8,
        "customer_name": "Rebecca Kim",
        "service_type": "Garage Door Off Track",
        "tech_name": "Scott M.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.8,
        "customer_name": "Samantha Jackson PhD",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Elizabeth C.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Gabriel Scott",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "William M.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.8,
        "customer_name": "Patricia Horn",
        "service_type": "Garage Door Repair",
        "tech_name": "Aaron H.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-08",
        "review_rate": 5.0,
        "customer_name": "Laura Jones",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Duane C.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Richard Smith",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kimberly H.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-06",
        "review_rate": 5.0,
        "customer_name": "Stephanie Schneider",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Ariel E.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Eric Travis",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kristie A.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.8,
        "customer_name": "Dana Hamilton",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Andrew H.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.8,
        "customer_name": "William Campbell",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Tyler R.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Christian Ward",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Mitchell S.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Ashley Wolfe",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kiara O.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Cynthia Nelson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Walter F.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.8,
        "customer_name": "Todd Fischer",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Renee D.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Amanda Rodriguez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Margaret N.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-27",
        "review_rate": 5.0,
        "customer_name": "Michaela Lee",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Robert J.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "Kayla Nguyen",
        "service_type": "Garage Door Installation",
        "tech_name": "Amanda A.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-07",
        "review_rate": 5.0,
        "customer_name": "Juan Mcdonald",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Heidi P.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.8,
        "customer_name": "Margaret Turner",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Autumn M.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Dawn Lopez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Timothy G.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-08",
        "review_rate": 5.0,
        "customer_name": "Brittney Roberts",
        "service_type": "Garage Door Repair",
        "tech_name": "Robert C.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.8,
        "customer_name": "Shelby Hernandez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kyle M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Joseph Rogers",
        "service_type": "Garage Door Installation",
        "tech_name": "Marissa H.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-20",
        "review_rate": 5.0,
        "customer_name": "Anne Walsh",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Drew V.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    }
]
export default LocationReviewsData;
